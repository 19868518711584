<template>
    <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
        <el-form ref="form"  label-width="100px">
                         <el-form-item size="small" label="厂家名称:" required>
                            <el-input v-model="item.manufactorName" style="width: 435px;" placeholder="请输入厂家名称" maxlength="64" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="地址:" required>
                            <el-input v-model="item.address" style="width: 435px;" placeholder="请输入地址" maxlength="255" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="电话:" required>
                            <el-input v-model="item.telephone" style="width: 435px;" placeholder="请输入电话" maxlength="20" show-word-limit ></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="联系人1:" required>
                            <el-input v-model="item.contactsOne" style="width: 435px;" placeholder="请输入联系人" maxlength="32" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="手机号1:" required>
                            <el-input v-model="item.phoneOne" style="width: 435px;" placeholder="请输入手机号" maxlength="11" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="联系人2:" required>
                            <el-input v-model="item.contactsTwo" style="width: 435px;" placeholder="请输入联系人" maxlength="32" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="手机号2:" required>
                            <el-input v-model="item.phoneTwo" style="width: 435px;" placeholder="请输入手机号" maxlength="11" show-word-limit></el-input>
                        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeEvent">取 消</el-button>
                <el-button size="small" type="primary" @click="saveBbiSupplier">确 定</el-button>
            </span>
    </el-dialog>
</template>
<script>
    export default {
        data () {
            return {
                show: false,
                item: this.editItem,
            }
        },
        props: {
            editItem: Object,
            editVisible: Boolean
        },
        methods: {
            closeEvent () {
                this.$emit('closeEdit')
            },
            async saveBbiSupplier() {
                let data = {
                    id:this.item.id,
                  manufactorName:this.item.manufactorName,
                  address:this.item.address,
                  telephone:this.item.telephone,
                  contactsOne:this.item.contactsOne,
                  phoneOne:this.item.phoneOne,
                  contactsTwo:this.item.contactsTwo,
                  phoneTwo:this.item.phoneTwo,
                };
                   if(!this.item.manufactorName) {
                       this.$message({
                           showClose: true,
                           message:'请输入厂家名称',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.address) {
                       this.$message({
                           showClose: true,
                           message:'请输入地址',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.telephone) {
                       this.$message({
                           showClose: true,
                           message:'请输入电话',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.contactsOne) {
                       this.$message({
                           showClose: true,
                           message:'请输入联系人1',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.phoneOne) {
                       this.$message({
                           showClose: true,
                           message:'请输入手机号1',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.contactsTwo) {
                       this.$message({
                           showClose: true,
                           message:'请输入',
                           type: 'error'
                       });
                       return
                   }
                   if(!this.item.phoneTwo) {
                       this.$message({
                           showClose: true,
                           message:'请输入手机号2',
                           type: 'error'
                       });
                       return
                   }

                let res = await this.$post("/admin/save_bbiSupplier" ,data)
                if (res.code == 200){
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('editSuccess')
                }else{
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            }
        }
    }
</script>
